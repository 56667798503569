import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { ContentCopy } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export default function AllCoursesTable({ header, rows }) {

  return (
    <TableContainer component={Paper} sx={{ minWidth: 650, maxWidth: 1200 }}>
      <Table sx={{ minWidth: 650, maxWidth: 1200, fontWeight: '600' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.length > 0
              ? header.map((col, index) => <TableCell key={index} align={'left'} sx={{ fontWeight: '700' }}>{col}</TableCell>)
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.hashEvent}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left" sx={{ fontWeight: '500', color: 'blue', maxWidth: '350px' }}>
                <Link to={`/cursos/${row.hashEvent}`}>
                  {row.eventName}
                </Link>
              </TableCell>
              <TableCell align="left">{new Date(row.creationDate).toLocaleString()}</TableCell>
              <TableCell align="left">{row.isNominated ? 'Si' : 'No'}</TableCell>
              <TableCell align="left">
                {row.hashEvent.slice(0,15)}...{row.hashEvent.slice(55)}
                <Tooltip sx={{ marginLeft: '5px' }} title="Copiar hash" arrow placement="right"><IconButton color="primary" onClick={() => navigator.clipboard.writeText(row.hashEvent)}><ContentCopy /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
