import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

export default function CourseParticipantsTable({ _header, _rows, _eventName }) {

  return (
    <TableContainer component={Paper} sx={{ minWidth: 650, maxWidth: '100%' }}>
      <Table sx={{ minWidth: 650, maxWidth: '100%', fontWeight: '600' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {_header.length > 0
              ? _header.map((col, index) => <TableCell key={index} align={(index === 0 || col === 'Estado') ? 'center' : 'left'} sx={{ fontWeight: '700' }}>{col}</TableCell>)
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {_rows.map((row) => (
            <TableRow
              key={row.idGalicia}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center" sx={{ fontWeight: '500' }}>
                {(row.idGalicia.toString()).padStart(4, "0")}
              </TableCell>
              <TableCell align="left">{row.participantName}</TableCell>
              <TableCell align="left">{row.surname}</TableCell>
              <TableCell align="left">{_eventName}</TableCell>
              <TableCell align="left">{row.participantMail}</TableCell>
              <TableCell align="center">{row.minted ? <Chip label="Minteado" sx={{ width: '105px', backgroundColor: '#CAE9C7', color: '#155A03', fontWeight: 'bold' }} /> : <Chip sx={{ width: '105px', backgroundColor: '#F1B2B2', color: '#750505', fontWeight: 'bold' }} label="No minteado" />}</TableCell>
              <TableCell align="left">{row.minted ? new Date(row.mintDate).toLocaleString() : null}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
