/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import "./page.css";
import axios from "axios";
import Papa from 'papaparse';
import ReactPlayer from 'react-player';
import { Row, Col } from "react-bootstrap";
import CRUDTable from "../Tables/CRUDTable";
import TabComponent from "./(add-properties)/AddProperties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { svgParser, svgReplace } from "../../utils/svgFunctions";
import { faCaretDown, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { MenuItem, Select, Button, Typography, TextField, Tooltip, Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material";
// useUser
import { useUser } from "../../context/userContext";
// date picker
import { formatDate } from "../../utils/formatFunctions";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// modales
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import WaitingModal from "../Modals/WaitingModal/WaitingModal";
import ConfirmationModal from "../Modals/ConfirmationModal/ConfirmationModal";
// svg
import SvgComponent from "../../utils/reactSvgTemplate";
// Glb
import GlbDisplay from '../GlbDisplay/GlbDisplay';

//svg actualizado cuando cambian los fields
const NewSvgComponent = ({ _newSvg }) => {
    return <div className="svg-component" id='svg-container' dangerouslySetInnerHTML={{ __html: _newSvg }} />
};


export default function NuevoCertificado({ _templates, _types }) {
    const { getHeader } = useUser();
    // svg
    const [renderSvg, setRenderSvg] = React.useState(
        <SvgComponent className="svg-component" />
    );
    const [svgFileContent, setSvgFileContent] = React.useState("");
    const [svgFields, setSvgFields] = React.useState("");
    const [courseFilled, setCourseFilled] = React.useState(Array(svgFields?.course?.length).fill(false));
    const [parseMatches, setParseMatches] = React.useState([]);
    // template
    const [templateName, setTemplateName] = React.useState("");
    const [newTemplate, setNewTemplate] = React.useState(null);
    const [fileExtension, setFileExtension] = React.useState(null)
    const [templateId, setTemplateId] = React.useState(null);
    const [templateType, setTemplateType] = React.useState("");
    const [isImage, setIsImage] = React.useState(null);
    const [isVideo, setIsVideo] = React.useState(null);
    const [isGlb, setIsGlb] = React.useState(null);
    // propiedades del certificados
    const [certificate, setCertificate] = React.useState({});
    const [properties, setProperties] = React.useState([]);
    const [isNominated, setIsNominated] = React.useState(null);
    const [eventType, setEventType] = React.useState("");
    const [eventTypeId, setEventTypeId] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);
    // csv
    const [csvData, setCsvData] = React.useState([]);
    const [certificates, setCertificates] = React.useState([]);
    // para el flujo
    const [createdEvent, setCreatedEvent] = React.useState(false);
    // Hash event
    const [hashEvent, setHashEvent] = React.useState(null);
    // modales
    const [createEventModal, setCreateEventModal] = React.useState(false);
    const [eventSuccessfullyCreated, setEventSuccessfullyCreated] = React.useState(false);
    const [certsSuccessfullyCreated, setCertsSuccessfullyCreated] = React.useState(false);
    const [uploadParticipantsModal, setUploadParticipantsModal] = React.useState(false);
    const [waitingModal, setWaitingModal] = React.useState(false);
    const [waitModal, setWaitModal] = React.useState(false);

    const newTemplateRef = React.useRef(null);

    // seleccionar el tipo de evento
    const handleSelectEventType = async (_name, _id) => {
        setCertificate((prevCertificate) => {
            return {
                ...prevCertificate,
                eventType: _name,
            };
        });
        setEventType(_name);
        setEventTypeId(_id);
    };

    const handleNominated = (value) => {
        if (createdEvent) return;
        else if (value.target.defaultValue === "Nominado") {
            setIsNominated(true);
        } else {
            setIsNominated(false);
        }
    };

    // actualiza el estado con los campos de SVG que tardan en llegar
    React.useEffect(() => {
        setCourseFilled(Array(svgFields?.course?.length).fill(false));
        setParseMatches(svgFields?.matches);
    }, [svgFields, svgFileContent]);


    /* TEMPLATES */
    const handleSelectNewTemplate = (event) => {

        const thisFileExtension = event.target.files[0]?.name.split(".")?.pop();
        setFileExtension(thisFileExtension);

        const imageExtensions = ["jpg", "jpeg", "png", "gif"];
        const videoExtensions = ["mp4"];
        const glbExtensions = ["glb"];

        setTemplateName("Nuevo template");
        setNewTemplate(event.target.files[0]);

        if (imageExtensions.includes(thisFileExtension)) {
            setIsImage(URL.createObjectURL(event.target.files[0]));
        } else if (videoExtensions.includes(thisFileExtension)) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => setIsVideo(reader.result);
        } else if (glbExtensions.includes(thisFileExtension)) {
            setIsGlb(URL.createObjectURL(event.target.files[0]));
        } else {
            setTemplateId("Nuevo template");
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(reader.result, "image/svg+xml");
                setSvgFileContent(svgDoc.documentElement.outerHTML);
            };
        }
    };

    const handleSelectTemplate = async (url, name, idTemplate) => {
        try {
            setTemplateName(name);
            setTemplateId(idTemplate);

            const thisFileExtension = url.split(".")?.pop();
            setFileExtension(thisFileExtension);

            const response = await axios.get(url);
            setTemplateType(response.headers["content-type"]);

            switch (response.headers["content-type"]) {
                case 'video/mp4':
                    setIsVideo(url);
                    setIsImage(null)
                    setSvgFileContent("")
                    setIsGlb(null)
                    break;
                case 'image/svg+xml':
                    setSvgFileContent(response.data)
                    setIsVideo(null)
                    setIsImage(null)
                    setIsGlb(null)
                    break;
                case 'binary/octet-stream':
                    setSvgFileContent(null)
                    setIsVideo(null)
                    setIsImage(null)
                    setIsGlb(url)
                    break;
                default:
                    setIsImage(url)
                    setIsVideo(null)
                    setIsGlb(null)
                    setSvgFileContent("")
                    break;
            }
        } catch (error) {
            console.error("Error when selecting the template", error);
        }
    };

    const reUploadNewTemplate = async () => {
        /* si la ref es null (quiere decir que ya hay un archivo cargado)
           entonces reinicio los estados de estas variables */
        if (newTemplateRef.current === null) {
            setSvgFileContent("")
            setNewTemplate(null)
            setIsVideo(null)
            setIsImage(null)
            setIsGlb(null)
            setTemplateName("");
            setTemplateId(null);
            setFileExtension(null);
            setTemplateType("");
        }
        setTimeout(() => {
            newTemplateRef.current.click();
        }, 1000);
    };


    /* SVG */
    React.useEffect(() => {
        setSvgFields(svgParser(svgFileContent));
    }, [svgFileContent]);

    const handleChange = (e, index, fieldType) => {
        const { value } = e.target;
        if (fieldType === "course") {
            setCourseFilled((prevFilled) => {
                const newFilled = [...prevFilled];
                newFilled[index] = value !== ""; //condicionalmente chequea si esta vacio el field
                return newFilled;
            });
        }
    };

    const handleSelectedDate = (date, field, index) => {
        const dateObj = new Date(date);
        const formattedDate = dateObj.toLocaleDateString();
        const trimmedDate = formattedDate.slice(0, 10);
        const usFormattedDate = formatDate(trimmedDate);
        setSelectedDate(usFormattedDate);

        setCourseFilled((prevFilled) => {
            const newFilled = [...prevFilled];
            newFilled[index] = date !== ""; //condicionalmente chequea si esta vacio el field
            return newFilled;
        });

        setCertificate((prevCertificate) => {
            return {
                ...prevCertificate,
                attributesEvent: {
                    ...prevCertificate.attributesEvent,
                    [field]: formattedDate,
                },
            };
        });
    };

    React.useEffect(() => {
        if (!parseMatches) {
            const new_svg = svgFileContent
            setRenderSvg(
                <NewSvgComponent className="svg-component" id='svg-container' _newSvg={new_svg} />
            );
        } else {
            const new_svg = svgReplace(svgFileContent, certificate, parseMatches);

            setRenderSvg(
                <NewSvgComponent className="svg-component" id='svg-container' _newSvg={new_svg} />
            );
        }
    }, [certificate, parseMatches, svgFileContent]);

    // setea las propiedades del certificado
    React.useEffect(() => {
        setCertificate({
            ...certificate,
            attributesEvent: {
                ...certificate.attributesEvent,
                properties,
            },
        });
    }, [properties]);

    /* CSV */
    React.useEffect(() => {
        if (csvData.length > 0) {
            const newRecords = csvData.map((contact, index) => {
                const parsedData = {};
                Object.keys(contact).forEach((key) => {
                    parsedData[key] = contact[key];
                });
                return {
                    id: certificates.length + index + 1,
                    ...parsedData,
                    ...certificate,
                };
            });

            setCertificates((prevCertificates) => [
                ...newRecords,
                ...prevCertificates,
            ]);
        }
    }, [csvData]);


    /* FUNCIONES DE SUBIDA */

    const createEvent = async () => {
        setCreatedEvent(true);
        setWaitingModal(true)

        let fileType;
        let idTemplate;

        try {

            if (templateName === "Nuevo template") {
                ({ idTemplate, fileType } = await uploadNewTemplate())
            }
            else {
                idTemplate = templateId;
                fileType = templateType;
            }

            const body = {
                eventName: certificate.dbEventName,
                attributesEvent: certificate.attributesEvent,
                idTemplate: Number(idTemplate),
                modifierUser: "tomic",
                isNominated: isNominated,
                eventType: Number(eventTypeId),
                fileType: fileType
            };

            const headers = await getHeader();
            const response = await axios.post(`${process.env.REACT_APP_API_BASE}/events`, body, { headers });

            console.log('Evento recién creado: ', response)

            setHashEvent(response.data.body.event.hashEvent);
            console.log('Hash del evento recién creado: ', response.data.body.event.hashEvent);

            await uploadToS3(response.data.body.event.hashEvent);
            setWaitingModal(false)
            if (!isNominated) setEventSuccessfullyCreated(true)
        } catch (error) {
            console.log(error);
        }
    };

    const uploadNewTemplate = async () => {

        try {
            const headers = await getHeader();

            const presignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=${newTemplate.name}&fileType=${newTemplate.type ? newTemplate.type : 'binary/octet-stream'}&case=template&hashEvent=0`, { headers });

            await fetch(presignedUrl.data.body.uploadURL, {
                method: "PUT",
                body: newTemplate,
            });

            const response = await axios.post(`${process.env.REACT_APP_API_BASE}/template?templateName=${(newTemplate.name).split('.')[0]}&modifierUser=gabo&uriTemplateS3=${process.env.REACT_APP_S3_BUCKET}/templates/${newTemplate.name}`, {}, { headers });


            return {
                idTemplate: response.data.body.idTemplate,
                fileType: newTemplate.type ? newTemplate.type : "binary/octet-stream",
            }

        } catch (error) {
            console.log(error)
        }
    };

    const uploadToS3 = async (_hashEvent) => {
        try {
            const headers = await getHeader();
            if (isVideo) {
                // subir video
                const templatePresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=file.mp4&fileType=video/mp4&case=eventTemplate&hashEvent=${_hashEvent}`, { headers });
                const response = await axios.get(isVideo, { responseType: 'blob' });
                await fetch(templatePresignedUrl.data.body.uploadURL, {
                    method: "PUT",
                    body: response.data,
                });

            } else if (isImage) {
                // subir imagen
                const templatePresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=file.jpeg&fileType=image/jpeg&case=eventTemplate&hashEvent=${_hashEvent}`, { headers });
                const response = await axios.get(isImage, { responseType: 'blob' });
                await fetch(templatePresignedUrl.data.body.uploadURL, {
                    method: "PUT",
                    body: response.data,
                });

            } else if (isGlb) {
                // subir GLB
                const templatePresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=file.glb&fileType=binary/octet-stream&case=eventTemplate&hashEvent=${_hashEvent}`, { headers });
                const response = await axios.get(isGlb, { responseType: 'blob' });
                await fetch(templatePresignedUrl.data.body.uploadURL, {
                    method: "PUT",
                    body: response.data,
                });
            } else {
                // subir SVG


                const svgHtml = svgReplace(svgFileContent, certificate, parseMatches);

                const blob = new Blob([!parseMatches ? svgFileContent : svgHtml], { type: 'image/svg+xml' });

                const templatePresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=file.svg&fileType=image/svg+xml&case=eventTemplate&hashEvent=${_hashEvent}`, { headers });

                await fetch(templatePresignedUrl.data.body.uploadURL, {
                    method: "PUT",
                    body: blob,
                });
            }

            // subir JSON
            const metadataPresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=metadata.json&fileType=application/json&case=eventTemplate&hashEvent=${_hashEvent}`, { headers });

            const metadataToUpload = certificate.attributesEvent;

            let svgAttributes;
            for (const key in metadataToUpload) {
                if (Object.hasOwnProperty.call(metadataToUpload, key) && key !== 'properties') {
                    svgAttributes = {
                        ...svgAttributes,
                        [key]: metadataToUpload[key]
                    }

                }
            }

            let attributes = [];
            if (metadataToUpload.properties.length > 0) {
                attributes = metadataToUpload.properties.map(attr => {
                    return {
                        "trait_type": attr.key,
                        "value": attr.value
                    }
                })
            };

            const nftMetadata = {
                "name": `${certificate.dbEventName}`,
                "attributes": [
                    ...attributes,
                    {
                        "trait_type": "Tipo de evento",
                        "value": eventType
                    }
                ],
                "svgAttributes": svgAttributes,
                "image": "",
                "description": `${certificate.dbEventName}`,
                "experienceDescription": certificate.expDescription,
                "eventType": eventType,
                "fileType": fileExtension,
            };

            await fetch(metadataPresignedUrl.data.body.uploadURL, {
                method: "PUT",
                body: JSON.stringify(nftMetadata),
            });

        } catch (error) {
            console.log(error)
        }
    };

    const uploadParticipantsToS3 = async () => {
        // le saco el id usado para la key de la tabla y paso a string la propiedad attributesEvent
        const newParticipantsArray = certificates.map((part) => {
            delete part.id;
            return {
                ...part,
                attributesEvent: JSON.stringify(part.attributesEvent)
            }
        })
        const body = {
            hashEvent: hashEvent,
            allParticipants: newParticipantsArray,
        };
        console.log("Participantes y hash: ", body);

        try {
            const headers = await getHeader();
            // subir a s3
            const csv = Papa.unparse(body.allParticipants);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const participantsPresignedUrl = await axios.get(`${process.env.REACT_APP_API_BASE}/user/presigned-url?fileName=participants.csv&fileType=text/csv&case=eventParticipants&hashEvent=${body.hashEvent}`, { headers });

            await fetch(participantsPresignedUrl.data.body.uploadURL, {
                method: "PUT",
                body: blob,
            });

            // await uploadParticipants(body);

            setCertsSuccessfullyCreated(true);

        } catch (error) {

        }
    };

    // Funcion final
    const handleSubmit = async () => {
        setWaitModal(true)
        await uploadParticipantsToS3();
        setWaitModal(false);
    };

    return (
        <>
            {/* MODALES */}
            <ConfirmationModal _open={createEventModal} _handleClose={() => setCreateEventModal(false)} _title={'Crear nuevo evento'} _function={createEvent} />
            <WaitingModal _open={waitModal} _handleClose={() => setWaitModal(false)} />
            <SuccessModal _open={eventSuccessfullyCreated} _handleClose={() => setEventSuccessfullyCreated(false)} _title={'Evento creado exitosamente'} _subtitle={`El evento ${certificate.dbEventName} fue creado correctamente. Su hash es ${hashEvent?.slice(0, 5)}...${hashEvent?.slice(62)}`} _redirect={`/review-certificado/${hashEvent}`} />   {/* Evento no nominado creado */}

            <WaitingModal _open={waitingModal} _handleClose={() => setWaitingModal(false)} _title={'Creando evento'} />
            <ConfirmationModal _open={uploadParticipantsModal} _handleClose={() => setUploadParticipantsModal(false)} _title={'Cargar participantes'} _function={handleSubmit} />
            <SuccessModal _open={certsSuccessfullyCreated} _handleClose={() => setCertsSuccessfullyCreated(false)} _title={'Evento creado exitosamente'} _subtitle={`Los certificados ya fueron creados y estan listos para el minteo`} _redirect={`/review-certificado/${hashEvent}`} />
            {/* Pagina */}
            <Row>
                {/* Primera mitad */}
                <Col md={12} xl={6}>
                    {/* Seleccionar template */}
                    <Row>
                        <FontAwesomeIcon className="icon-template" size="lg" icon={faFileLines} />
                        <FontAwesomeIcon className="icon" icon={faCaretDown} />

                        <Select
                            variant="outlined"
                            value={templateName}
                            displayEmpty
                            disabled={createdEvent}
                            renderValue={(selected) => {
                                if (selected === "") {
                                    return <span>Seleccionar template</span>;
                                }

                                return selected;
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{ width: "50%" }}
                        >
                            <MenuItem value="Nuevo template" onClick={reUploadNewTemplate}>
                                {/* <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif,.mp4,.json" name="newTemplate" id="newTemplate" onChange={handleSelectNewTemplate} /> */}
                                <em>Nuevo Template</em>
                            </MenuItem>
                            {Array.isArray(_templates) && _templates.length > 0
                                ? _templates.map((file) => (
                                    <MenuItem key={file.idTemplate} value={file.templateName}
                                        onClick={() => handleSelectTemplate(file.uriTemplateS3, file.templateName, file.idTemplate)}>
                                        {file.templateName}
                                    </MenuItem>
                                ))
                                : null}
                        </Select>
                    </Row>

                    {/* Datos del evento */}
                    <Row className="mt-5">
                        <Typography variant="h6" component={"h4"} sx={{ color: "black", fontWeight: "bold" }} className="px-0 subtitles">
                            Datos del evento
                        </Typography>

                        {/* CAMPOS FIJOS */}
                        {templateId !== "" ? (
                            <div className="ps-0 pe-5 my-3">
                                <TextField
                                    fullWidth
                                    disabled={createdEvent}
                                    type="text"
                                    className={`my-input filled certificate-form`}
                                    onChange={(e) => {
                                        setCertificate((prevCertificate) => {
                                            return {
                                                ...prevCertificate,
                                                dbEventName: e.target.value,
                                            };
                                        });
                                    }}
                                    label="Nombre del evento"
                                />

                                <Select
                                    value={eventType}
                                    disabled={createdEvent}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected === "") {
                                            return <span>Tipo de evento</span>;
                                        }

                                        return selected;
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                    sx={{ width: "75%" }}>
                                    {/* <MenuItem disabled value="">
                                        <span>Tipo de evento</span>
                                    </MenuItem> */}
                                    {Array.isArray(_types) && _types.length > 0
                                        ? _types.map((type) => (
                                            <MenuItem key={type.idType} value={type.typeName}
                                                onClick={() => handleSelectEventType(type.typeName, type.idType)}>
                                                {type.typeName}
                                            </MenuItem>
                                        ))
                                        : null}
                                </Select>
                                <br />
                                <FormControl className="form-nominated">
                                    <label id="demo-row-radio-buttons-group-label">
                                        ¿Evento Nominado?
                                    </label>

                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" disabled={createdEvent}
                                        onChange={(value) => handleNominated(value)}>
                                        <FormControlLabel
                                            value="Nominado"
                                            disabled={createdEvent}
                                            control={<Radio />}
                                            label="Si"
                                        />
                                        <FormControlLabel
                                            value="NoNominado"
                                            disabled={createdEvent}
                                            control={<Radio />}
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                <TextField
                                    fullWidth
                                    disabled={createdEvent}
                                    type="text"
                                    className={`my-input filled certificate-form mt-4`}
                                    onChange={(e) => {
                                        setCertificate((prevCertificate) => {
                                            return {
                                                ...prevCertificate,
                                                expDescription: e.target.value,
                                            };
                                        });
                                    }}
                                    label="Descripcion de la experiencia"
                                />

                            </div>
                        ) : null}

                        {svgFields?.course?.map((field, index) =>
                            field.toLowerCase() === "date" || field.toLowerCase() === "fecha" ? ( // si el valor de field es 'date', pone un date-picker
                                <div className="ps-0 pe-5 mt-3" key={index}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                                        <DatePicker
                                            label="Fecha"
                                            value={selectedDate}
                                            inputFormat="DD/MM/YYYY"
                                            disabled={createdEvent}
                                            onChange={(date) => handleSelectedDate(date, field, index)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            ) : (
                                <div className="ps-0 pe-5 mt-3" key={index}>
                                    <TextField
                                        key={index}
                                        disabled={createdEvent}
                                        type="text"
                                        className={`my-input ${courseFilled[index] ? "filled" : ""} certificate-form`}
                                        onChange={(e) => {
                                            setCertificate({
                                                ...certificate,
                                                attributesEvent: {
                                                    ...certificate.attributesEvent,
                                                    [field]: e.target.value,
                                                },
                                            });
                                            handleChange(e, index, "course");
                                        }}
                                        label={field.toString().toUpperCase()}
                                        variant="outlined"
                                    />
                                </div>
                            )
                        )}
                    </Row>

                    {/* Propiedades opcionales */}
                    <Row>
                        <Typography variant="h6" component="h4" className="my-4 px-0 subtitles" sx={{ fontStyle: "italic" }}>
                            Propiedades Opcionales
                            <Tooltip sx={{ marginLeft: "10px" }} color="secondary" title="Atributos comunes a todos los NFTs del evento" arrow placement="right">
                                <HelpOutlineIcon />
                            </Tooltip>
                        </Typography>
                        <TabComponent setProperties={setProperties} properties={properties} eventCreated={createdEvent} />
                    </Row>
                </Col>

                {/* SVG */}
                <Col md={12} xl={6} style={{ paddingRight: '5%' }}>
                    <div>
                        <Typography variant="h6" component="h3" sx={{ color: "black", fontWeight: "600", fontSize: "16px" }}>
                            Vista previa del certificado
                        </Typography>

                        {templateId || isImage || isVideo || isGlb ? (
                            <>
                                {isImage && <img src={isImage} alt='imagen' className="custom-upload" style={{ maxHeight: '40rem' }} />}
                                {templateId && renderSvg}
                                {isVideo && <ReactPlayer url={isVideo} controls />}
                                {isGlb && <GlbDisplay _file={isGlb} />

                                }
                            </>
                        ) : (
                            <label style={{ border: newTemplate ? "none" : "", justifyContent: newTemplate ? "flex-start" : "center" }} htmlFor="newTemplate" className="custom-upload" ref={newTemplateRef}>
                                {!newTemplate ? (
                                    <>
                                        <Row className="justify-content-center">
                                            <CloudUploadIcon color="disabled" sx={{ fontSize: "6rem", margin: "0px", maxWidth: "340px", borderRadius: "5%" }} />
                                        </Row>
                                        <Row>
                                            <Typography className="text-center" variant="h6" sx={{ color: "#999999" }}>
                                                Subir Nuevo Template
                                            </Typography>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <NewSvgComponent className="custom-upload" id='svg-container' newSvg={newTemplate} />
                                    </>
                                )}
                                <input type="file" accept=".jpg,.jpeg,.png,.svg,.gif,.mp4,.glb" name="newTemplate" id="newTemplate" onChange={handleSelectNewTemplate} />
                            </label>
                        )}
                    </div>
                </Col>
            </Row>
            {/* Crear evento */}
            {!createdEvent && (
                <Row className="mt-3">
                    <Col xs={12} lg={12} xxl={12} style={{ padding: '0px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            className="event-btn"
                            disabled={isNominated === null || eventType === "" || !certificate.dbEventName || templateName === ""}
                            onClick={() => setCreateEventModal(true)}
                        >
                            Crear Evento
                        </Button>
                    </Col>
                </Row>
            )}
            {/* Participantes */}
            {createdEvent && isNominated && (
                <Row className="mt-3">
                    <Col xs={12} lg={12} xxl={12}>
                        <h4 className="mt-3 mb-3 h4-fields">Datos de asistentes</h4>
                        <CRUDTable
                            setCertificates={setCertificates}
                            setCsvData={setCsvData}
                            certificate={certificate}
                            certificates={certificates}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            className="event-btn"
                            disabled={!(csvData.length > 0 && certificates.length > 0)}
                            onClick={() => setUploadParticipantsModal(true)}
                        >
                            Crear Certificados
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
}
