/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import axios from "axios";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import BounceLoader from "react-spinners/BounceLoader";
import CourseParticipantsTable from "../components/Tables/CourseParticipantsTable";
// useUser
import { useUser } from "../context/userContext";
import SlideShow from "../components/SlideShow/SlideShow";
import ReactPlayer from "react-player";
import GlbDisplay from "../components/GlbDisplay/GlbDisplay";
import { QRCode } from "react-qrcode-logo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const limit = 50; // esto va fijo

const Course = () => {
  const { hash } = useParams();
  const { getHeader } = useUser();
  const tableHeader = [
    "ID Galicia",
    "Nombre",
    "Apellido",
    "Curso",
    "Estado",
    "Fecha minteo",
    "Wallet"
  ];
  const [thisEvent, setThisEvent] = React.useState({});
  const [participants, setParticipants] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [templateUrl, setTemplateUrl] = React.useState(null);
  const [open, setOpen] = React.useState(false)

  const getEventData = async (_hash, _limit, _lastKey) => {
    try {
      const headers = await getHeader();
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/event?hashEvent=${_hash}&count=${_limit}&lastKey=${_lastKey}`,
        { headers }
      );
      console.log('respuesta1', response)
      setThisEvent(response.data.body.event);
      setParticipants([...participants, ...response.data.body.participants]);
      setPaginationData(response.data.body.pagination);
      if (response.data.body.event.fileType !== "image/svg+xml") {
        const template = await getTemplate(
          response.data.body.event.idTemplate,
          headers
        );
        setTemplateUrl(template);
      }
    } catch (error) {
      console.log(error.response.data);
    } finally {
      setInitialLoading(false);
    }
  };

  const getTemplate = async (_id, _headers) => {
    try {
      const template = await axios.get(
        `${process.env.REACT_APP_API_BASE}/template?idTemplate=${_id}`,
        { headers: _headers }
      );
      console.log(template.request.response);
      console.log(JSON.parse(template.request.responseText));
      return template.data.body.uriTemplateS3;
    } catch (error) {
      console.error(error);
    }
  };

  

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(hash);
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${hash}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click(); 
    document.body.removeChild(downloadLink);
  };

  // paginacion
  const handlePaginationChange = () => {
    console.log('pag', paginationData.nextPageKey)
    getEventData(hash, limit, paginationData.nextPageKey);
  };

  React.useEffect(() => {
    getEventData(hash, limit, "");
  }, [hash]);

  const exportTable = async (data) => {
    try {
      const formattedData = data.map((item) => {
        return {
          minted: item.minted,
          idGalicia: item.idGalicia,
          participantName: item.participantName,
          surname: item.surname,
          walletAddress: item.walletAddress,
          transactionStatus: item.transactionStatus,
          modificationDate: new Date(item.modificationDate).toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          participantMail: item.participantMail,
          hashEvent: item.hashEvent,
          mintDate: new Date(item.mintDate).toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }),
          hashTransaction: item.hashTransaction,
        };
      });
  
      const csv = Papa.unparse(formattedData, {
        columns: [
          "minted",
          "idGalicia",
          "participantName",
          "surname",
          "walletAddress",
          "transactionStatus",
          "modificationDate",
          "participantMail",
          "hashEvent",
          "mintDate",
          "hashTransaction",
        ],
      });
  
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${thisEvent.eventName} participants.csv`);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllEventsDownload = async (_id, _headers) => {
    const headers = await getHeader();
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_BASE}/events/all?hashEvent=${hash}`,
        
        { headers }
      );
      const participantes = JSON.parse(data.request.response)
      console.log('participantes', participantes);
      console.log('participantes2', participantes.body.participants);
      exportTable(participantes.body.participants)
    } catch (error) {
      console.error(error);
    }
  };

  if (initialLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
        }}
      >
        <BounceLoader color="#ff6600" />
      </div>
    );
  else
    return (
      <Container>
        <Row>
          <Col md={3}>
            <Typography
              variant="h6"
              component="h6"
              color="primary"
              sx={{ fontSize: "1rem", fontWeight: "bold", margin: "1rem 0rem" }}
            >
              Datos del evento
            </Typography>
            <Typography variant="h6" component="h6" className="data-event-name">
              Nombre del evento:
              <Typography
                variant="label"
                component="label"
                className="data-event-desc"
              >
                {thisEvent.eventName}
              </Typography>
            </Typography>
            <Typography variant="h6" component="h6" className="data-event-name">
              Tipo de evento:
              <Typography
                variant="label"
                component="label"
                className="data-event-desc"
              >
                {thisEvent.eventType}
              </Typography>
            </Typography>
            <Typography variant="h6" component="h6" className="data-event-name">
              Evento nominado:
              <Typography
                variant="label"
                component="label"
                className="data-event-desc"
              >
                {thisEvent.isNominated ? "Si" : "No"}
              </Typography>
            </Typography>
            {thisEvent.attributesEvent.Descripción ? (
              <Typography
                variant="h6"
                component="h6"
                className="data-event-name"
              >
                Descripción:
                <Typography
                  variant="label"
                  component="label"
                  className="data-event-desc"
                >
                  {thisEvent.attributesEvent.Descripción}
                </Typography>
              </Typography>
            ) : null}

            {thisEvent.attributesEvent.Lugar ? (
              <Typography
                variant="h6"
                component="h6"
                className="data-event-name"
              >
                Lugar:
                <Typography
                  variant="label"
                  component="label"
                  className="data-event-desc"
                >
                  {thisEvent.attributesEvent.Lugar}
                </Typography>
              </Typography>
            ) : null}
            {thisEvent.attributesEvent.Fecha ? (
              <Typography
                variant="h6"
                component="h6"
                className="data-event-name"
              >
                Fecha de Creación:
                <Typography
                  variant="label"
                  component="label"
                  className="data-event-desc"
                >
                  {thisEvent.attributesEvent.Fecha}
                </Typography>
              </Typography>
            ) : null}
            {console.log(thisEvent)}
            {thisEvent.attributesEvent.properties.length > 0 ? (
              <Typography
                variant="h6"
                component="h6"
                className="data-event-name"
              >
                Propiedades opcionales:
                {thisEvent.attributesEvent?.properties.map((element, index) => (
                  <div key={index} className="d-flex flex-row">
                    <Typography
                      variant="label"
                      component="label"
                      sx={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      
                      {element.key}
                    </Typography>
                    <div style={{ padding: "0 1rem" }}>-</div>
                    <Typography
                      variant="label"
                      component="label"
                      sx={{ fontSize: "1rem", fontWeight: "600" }}
                    >
                      {element.value}
                    </Typography>
                  </div>
                ))}
              </Typography>
            ) : null}
          </Col>
          <Col md={5}>
            <div style={{ overflow: "hidden" }}>
              {thisEvent && thisEvent.fileType === "image/svg+xml" ? (
                <SlideShow _participants={participants} _hashEvent={hash} />
              ) : thisEvent.fileType === "video/mp4" ? (
                <ReactPlayer
                  url={templateUrl}
                  controls
                  style={{ maxHeight: "35rem", marginBottom: "1rem" }}
                />
              ) : thisEvent.fileType === "binary/octet-stream" ? (
                <GlbDisplay _file={templateUrl} />
              ) : (
                <img
                  src={templateUrl}
                  alt="Certificado"
                  className="review-img"
                  style={{ maxHeight: "35rem", marginBottom: "1rem" }}
                />
              )}
            </div>
          </Col>
          <Col md={4} className="mt-1 text-center">
            <Typography
              variant="h6"
              component="h6"
              sx={{ fontSize: "1rem", fontWeight: "bold", margin: "1rem 1rem" }}
            >
              QR del curso
            </Typography>
            <QRCode value={hash} eyeRadius={10} id="qr-code" />

            <Typography variant="h5" component="h6">
              {hash?.slice(0, 8)}...{hash?.slice(59)}
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copiado"
              >
                <IconButton onClick={handleTooltipOpen}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <br />
            <Button className="descargar-btn" sx={{color: 'white'}} onClick={downloadQRCode}>
              Descargar QR
            </Button>
          </Col>
        </Row>

        <hr style={{marginTop: '1rem'}}/>

        <div className="d-flex justify-content-between align-items-center my-3">
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={{ fontSize: "1.75rem", fontWeight: "bold" }}
          >
            Evento {thisEvent.eventName}
          </Typography>
          {thisEvent ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={getAllEventsDownload}
            >
              Exportar
            </Button>
          ) : null}
        </div>
        {thisEvent ? (
          <>
            <CourseParticipantsTable
              _rows={participants}
              _header={tableHeader}
              _eventName={thisEvent.eventName}
            />
            {paginationData.nextPageKey ? (
              <Row className="my-3">
                <Col className="mx-4 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePaginationChange}
                  >
                    Cargar más participantes
                  </Button>
                </Col>
              </Row>
            ) : null}
          </>
        ) : (
          <div>Evento no nominado</div>
        )}
      </Container>
    );
};

export default Course;
