import * as React from "react";
import { Row } from "react-bootstrap";
import { Button, IconButton, Tooltip, Typography, Grid } from "@mui/material";
import axios from "axios";
import { useUser } from "../../context/userContext";
import AddIcon from "@mui/icons-material/Add";
import TransactionsTable from "../../components/Tables/TransactionsTable";
import fileDownload from 'js-file-download';



const RelayerTransactions = ({ _transactions }) => {
  const [isLoading, setIsloading] = React.useState(false);
  const participants = "hola";
  const { getHeader } = useUser();

  console.log('tr', _transactions)

  const getRelayerTransactions = async (_id, _headers) => {
    setIsloading(true);
    const headers = await getHeader();
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_BASE}/relayer/all`,
        { headers }
      );

      const pre = JSON.parse(data.request.response);
      console.log(pre);
      const transactions = JSON.parse(pre.body)
      console.log('tra', transactions.message)
      const csvHeader = "idGalicia;eventName;mintDate;minted;attributesTransaction;hashTransaction\n";
      
      


    // Combinar el encabezado y el contenido del CSV
    const csvData = csvHeader + transactions.message;

    /* fileDownload(csvData, 'relayersTransactions.csv'); */
      /* console.log("json parse de body", JSON.parse(pre.body).downloadUrl); */
      /* const downloadUrl = JSON.parse(pre.body).downloadUrl;
      const response = await fetch(downloadUrl, { headers });
      console.log('response final',response)*/
      fileDownload(csvData, 'relayers.csv'); 
      setIsloading(false);
    } catch (error) {
      setIsloading(false);

      console.error(error);
    }
  };


  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <Typography
            variant="h6"
            component="h2"
            color="primary"
            sx={{ fontSize: "1.25rem" }}
          >
            Listado de transacciones
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={isLoading ? null : <AddIcon />}
            onClick={getRelayerTransactions}
          >
            {isLoading ? "Cargando" : "Exportar"}
          </Button>
        
        </Grid>
      </Grid>
      <TransactionsTable
        header={[
          "ID Galicia",
          "Fecha de Creación",
          "Evento",
          "Minteado",
          "Costo",
          "Hash de la transacción",
        ]}
        rows={_transactions}
      />
    </>
  );
};

export default RelayerTransactions;
